import React from 'react'

const Comments = () => {
  return (
    <>
      <button type="button" className="bg-indigo-500 flex flex-row mx-auto max-w-sm" disabled>
        
        Coming to you Soon...
      </button>
    </>
  )
}

export default Comments